<template>
  <div v-if="!loading" class="auditorium common-bg">
    <div class="iframe-holder">
      <div class="container-lg py-5">
        <div
          class="row mb-3 justify-content-center d-block d-md-block d-lg-none"
        >
          <div class="col-12">
            <router-link
              to="/lobby"
              class="btn btn-outline-light btn-sm text-uppercase"
              >Back to Lobby
            </router-link>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/img/jpm-chase-logo.png"
            class="img-fluid"
            alt="logo"
          />
        </div>
        <div class="row mb-3">
          <div class="col text-center">
            <a
              v-show="settings.emea_event1_button_label"
              :href="settings.emea_event1_button_url"
              class="btn btn-outline-light btn-sm text-uppercase mr-2 font-weight-bold"
              target="_blank"
              >{{ settings.emea_event1_button_label }}</a
            >
          </div>
          <div class="col text-center">
            <a
              v-show="settings.emea_event2_button_label"
              :href="settings.emea_event2_button_url"
              class="btn btn-outline-light btn-sm text-uppercase mr-2 font-weight-bold"
              target="_blank"
              >{{ settings.emea_event2_button_label }}</a
            >
          </div>
          <div class="col text-center">
            <a
              v-show="settings.emea_event3_button_label"
              :href="settings.emea_event3_button_url"
              class="btn btn-outline-light btn-sm text-uppercase mr-2 font-weight-bold"
              target="_blank"
              >{{ settings.emea_event3_button_label }}</a
            >
          </div>
          <div class="col text-center">
            <a
              v-show="settings.emea_event4_button_label"
              :href="settings.emea_event4_button_url"
              class="btn btn-outline-light btn-sm text-uppercase mr-2 font-weight-bold"
              target="_blank"
              >{{ settings.emea_event4_button_label }}</a
            >
          </div>

          <div class="col-lg-12 text-center mt-3">
            <img
              src="@/assets/img/aud/DEI Week 2024 - Klobbi Auditorium_EMEA v2.jpg"
              class="img-fluid"
              alt="Auditorium_APAC"
            />
          </div>
        </div>
        <div class="d-flex">
          <a
            href="/lobby"
            class="btn btn-outline-light btn-sm text-uppercase mr-2 d-none d-md-none d-lg-flex font-weight-bold"
            >BACK TO LOBBY</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Api from "@/assets/js/Api";
import util from "@/assets/js/util";
import { mapState } from "vuex";

export default {
  name: "auditorium",
  computed: mapState(["settings"]),
  watch: {
    settings() {
      console.log(this.settings);
      if (!this.settings.is_emea_open) {
        location.href = "auditorium";
      }
    },
  },
  data() {
    return {
      loading: true,
      frame_slido: false,
      attendance_interval: false,
      hide_video: false,
      hide_btns: true,
    };
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.attendance_interval);
    next();
  },
  methods: {
    setAttendance() {
      return;
      let self = this;

      Api.post("/aud_attendance/store", {}, util.getAuthConfig()).then(
        (res) => {
          let attendance_id = res.data.attendance_id;
          self.attendance_interval = setInterval(function () {
            Api.get(
              `/aud_attendance/update?attendance_id=${attendance_id}`,
              util.getAuthConfig()
            ).then((res) => console.log(res));
          }, 10000);
        }
      );
    },
    activateSlido() {
      this.frame_slido = true;
    },
    activateNotes() {
      this.frame_slido = false;
    },
  },
  mounted() {
    let self = this;
    $(document).ready(function () {
      var $videoSrc;
      $(".video-btn").click(function () {
        $videoSrc = $(this).data("src");
      });

      $("#openingCeremonyModal").on("shown.bs.modal", function (e) {
        self.hide_video = false;
        setTimeout(() => {
          $("#video").attr("src", $videoSrc);
        }, 500);
      });

      $("#openingCeremonyModal").on("hide.bs.modal", function (e) {
        self.hide_video = true;

        $("#video").attr("src", $videoSrc);
      });
    });

    setTimeout(() => {
      self.setAttendance();
    }, 3000);
    setTimeout(() => {
      if (!this.settings.is_emea_open) {
        location.href = "auditorium";
      } else {
        this.loading = false;
      }
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.auditorium {
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .iframe-holder {
    height: 100%;

    .toggle-btns {
      position: absolute;
      z-index: 100;
      top: -38px;
      background-color: #503628;
      width: 100%;
      display: flex;
      justify-content: space-around;

      .btn {
        background: none;
        border: 0;
        border-radius: 0;
        color: #fff;
        outline: none;
        box-shadow: none;
        text-transform: uppercase;
        flex: 1;
      }

      & .active {
        font-weight: 500;
        color: #fff;
        border-bottom: 3px solid #fff;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .auditorium {
    & .iframe-holder {
      & .embeds {
        height: unset !important;
      }

      & .toggle-btns {
        margin: 20px 0 0 0;
        position: relative;
        top: 0;
      }
    }
  }
}
</style>
